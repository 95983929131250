<template>
  <div class="contact">
    <div class="container">
      <div class="title">CONTACT US 联系我们</div>
      <div class="detail">请通过下方联系方式或地址与我们取得联系</div>
      <div class="img"><img src="../assets/images/map.png" /></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped>
.contact {
  width: 100%;
  height: 700px;
  background-color: #19243a;
}
.container {
  width: 1000px;
  height: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.title {
  width: 1000px;
  text-align: left;
  font-size: 20px;
  color: #313a4b;
  border-bottom:2px solid #313a4b;
}
.detail {
  width: 800px;
  text-align: left;
  font-size: 16px;
  color: #e6e6e6;
}
.img img {
  width: 800px;
  height: auto;
}
</style>