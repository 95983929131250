<template>
  <div class="container">
    <div class="tabtopout" :style="'width:' + clientWidth + ';'">
      <div class="tabtop">
        <div class="logo"><img src="../assets/images/logo2.png" /></div>
        <div class="titlecontainer">
          <router-link class="titlelink title" to="/home">首页</router-link>
          <router-link class="titlelink title" to="/product">产品中心</router-link>
          <router-link class="titlelink title" to="/list">案例展示</router-link>
          <router-link class="titlelink title" to="/develop">开发设备板</router-link>
          <router-link class="titlelink title" to="/contact">联系我们</router-link>
        </div>
      </div>
    </div>
    <div class="body">
      <router-view></router-view>
    </div>
    <div class="footerout" :style="'width:' + clientWidth + ';'">
      <div class="footer">
        <div class="message">
          <div class="detail">联系电话</div>
          <div class="detail">0574-88059995</div>
          <div class="detail">邮箱</div>
          <div class="detail">xiaotuizi0220@126.com</div>
          <div class="detail">地址</div>
          <div class="detail">浙江省宁波市和邦大厦A座2111室</div>
        </div>
        <!-- <div class="link">
          <div class="linkimg"></div>
          <div class="textbox">关注软通教育公众号</div>
        </div> -->
        <div class="conact">
          <div class="item">
            <label>留言内容</label>
            <textarea id="licenseText" maxlength="200" v-model="formData.text"></textarea>
          </div>
          <div class="item">
            <label>称呼</label>
            <input class="input" v-model="formData.name" />
          </div>
          <div class="item">
            <label>电话</label>
            <input class="input" v-model="formData.phone" />
          </div>
          <div class="item">
            <label>公司</label>
            <input class="input" v-model="formData.company" />
          </div>
          <div class="sub-botton" @click="submitmessage">提交</div>
        </div>
      </div>
      <div class="buttommsg" :style="'width:' + clientWidth + ';'">
        2021-2024 宁波软通教育科技有限公司版权所有 <a href="https://beian.miit.gov.cn" target="_blank">浙ICP备2023028632号-1</a>
      </div>
    </div>
  </div>
</template>

<script>
import Home from "@/views/home.vue";
import axios from 'axios'
export default {
  data() {
    return {
      clientWidth: "",
      formData: {
        text: '',
        name: '',
        phone: '',
        company: ''
      }
    };
  },
  created() {
    this.clientWidth = `${document.documentElement.clientWidth}`;
    console.log(this.clientWidth);
    document.title = '宁波软通教育'
  },
  components: {
    Home,
  },
  methods: {
    submitmessage() {
      this.$confirm('确定提交?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.post('http://121.41.26.102:8090/leaveMessage/add', this.formData).then(res => {
          if (res.data.code == 200) {
            this.$message('提交成功');
            this.formData = {}
          }
        })
      }).catch(() => {
      });

    }
  }
};
</script>

<style scoped>
.tabtopout {
  /* width: 100%; */
  height: 80px;
  background-color: #121042;
}

.tabtop {
  justify-content: center;
  align-items: center;
  width: 1000px;
  margin: 0 auto;
  height: 80px;
  background-color: #121042;
  display: flex;
  justify-content: center;
}

.logo img {
  height: 50px;
  width: auto;
  margin-bottom: 10px;
}

.titlecontainer {
  width: 760px;
  height: 80px;
}

.title {
  display: inline-block;
  width: 152px;
  color: #e6e6e6;
  font-size: 18px;
  text-align: center;
  height: 80px;
  line-height: 80px;
}

a:link {
  color: #e6e6e6;
  text-decoration: none;
}

a:hover {
  background-color: #3777ff;
}

.footerout {
  /* width: 100%; */
  height: 400px;
  background-color: #19243a;
}

.footer {
  justify-content: space-between;
  align-items: center;
  width: 1000px;
  height: 250px;
  background-color: #19243a;
  display: flex;
  margin: 0 auto;
  border-bottom: 2px solid #27354f;
}

.buttommsg {
  /* width: 100%; */
  height: 150px;
  line-height: 150px;
  text-align: center;
  font-size: 12px;
  color: #313a4b;
}

.footer .message {
  width: 300px;
  height: 160px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
}

.footer .message .detail {
  height: 40px;
  line-height: 40px;
  width: 140px;
  color: #e6e6e6;
  flex-wrap: wrap;
  font-size: 14px;
  white-space: nowrap;
}

.footer .link {
  width: 150px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.link .linkimg {
  width: 120px;
  height: 120px;
  background-color: #fff;
}

.link .textbox {
  margin-top: 10px;
  width: 120px;
  height: 20px;
  color: #e6e6e6;
  font-size: 13px;
}

.conact {
  margin: 10px 0;
  width: 400px;
  height: 200px;
  color: white;
}

.conact .item {
  margin: 12px 0;
  display: flex;
  align-items: center;
}

.conact .item label {
  width: 80px;
}

.conact .item textarea {
  width: 200px;
  height: 50px;
}

.conact .item input {
  width: 200px;
}

.sub-botton {
  width: 80px;
  height: 30px;
  color: white;
  background-color: #505064;
  border-radius: 6px;
  text-align: center;
  line-height: 30px;
  margin-left: 220px;
}

.sub-botton:hover {
  cursor: pointer;
}
</style>