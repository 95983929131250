<template>
  <div class="body">
    <div class="img1">
      <img :src="require(`../assets/images/${currentimg}`)" />
      <div class="page">
        <el-pagination 
        @current-change="handleCurrentChange"
        :current-page="currentPage" 
        background layout="prev, pager, next" :total="20">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "List",
  data(){
    return{
    currentPage:1,
    length:2,
    currentimg:"list.jpg",
    img:[
      "list.jpg",
      "list1.jpg"
    ]
    }
  },
  methods:{
    handleCurrentChange(index){
      this.currentimg=this.img[index-1]
    }
  }
};
</script>

<style scoped>
.img1{
  position: relative;
}
.img1 img {
  display: block;
  width: 100%;
  height: auto;
}
.page{
  position: absolute;
  bottom:30px;
  left: 50%;
  transform: translateX(-50%);
}
</style>