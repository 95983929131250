<template>
  <div class="body">
    <div class="img1">
      <img src="../assets/images/product.jpg" >
    </div>
  </div>
</template>

<script>
export default {
      name:'Product'
}
</script>

<style scoped>
.img1 img{
    display: block;
    width: 100%;
    height: auto;
}

</style>