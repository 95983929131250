import VueRouter from 'vue-router'
import Home from '../views/home.vue'
import Product from '../views/product.vue'
import Develop from '../views/develop.vue'
import List from '../views/list.vue'
import Contact from '../views/contact.vue'
export default new VueRouter({
  routes: [
        {
          path: '/',
          redirect: '/home',

        },
        {
          path: '/home',
          component: Home,
          meta: {
            title: '登录',
          }
        },
        {
          path: '/product',
          component: Product
        },
        {
          path: '/develop',
          component: Develop
        },
        {
          path: '/list',
          component: List
        },
        {
          path: '/contact',
          component: Contact
        }
  ]
})

