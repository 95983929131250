<template>
  <div class="body">
      <div class="img1"><img src="../assets/images/home1.jpg" /></div>
      <div class="img1"><img src="../assets/images/home2.png" /></div>
      <div class="img1"><img src="../assets/images/home3.jpg" /></div>
      <div class="img1"><img src="../assets/images/home4.jpg" /></div>
      <div class="img1"><img src="../assets/images/home5.jpg" /></div>
      <div class="img1"><img src="../assets/images/home6.jpg" /></div>
  </div>
</template>

<script>
export default {
  name: "Home"
};
</script>

<style scoped>
.body{
  width:100%
}
.img1{
  width: 100%;
  height: auto;
}
.img1 img {
  width: 100%;
  height: auto;
  display: block;
}
</style>